<template>
  <b-container>
    <b-row v-if="!loading">
      <b-col>
        <b-card>
          <b-container>
            <b-row>
              <b-col>
          <p><b-link :to="{ name: 'Comms' }">back to communications</b-link></p>
          <h2>
            <b-button class="mr-2" v-if="permission" variant="outline-primary" :to="{ name: 'CommEdit', params: { id: row.id }}">Edit</b-button>
          </h2>
          <div><strong>Communication content</strong></div>
          <div class="mb-4">{{row.body}}</div>
          <div><strong>Risks</strong></div>
          <div class="mb-4">{{row.risks}}</div>
          <div><strong>Detection difficulty</strong></div>
          <div class="mb-4">{{row.difficulty}}</div>
          <div><strong>Comments</strong></div>
          <div class="mb-4">{{row.comments}}</div>
          <div><strong>Created by</strong> {{row.user}} at {{row.created_at}}</div>
          <div v-if="row.user_updated"><strong>Last updat by</strong> {{row.user_updated}} at {{row.updated_at}}</div>
              </b-col>
            </b-row>

          </b-container>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import ac from '../libs/accesscontrol'

export default {
  name: 'Alert',
  beforeDestroy () {
  },
  components: {
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.permission = ac.can(this.user.acgroups).createAny('extsource').granted
    this.loadRows()
  },
  data () {
    return {
      loading: true,
      permission: false,
      risks: '',
      row: ''
    }
  },
  methods: {
    loadRows: async function () {
      this.$logger.debug('load Comm started')
      this.loading = true
      try {
        let apiName = 'sql'
        let path = `/comm/${this.$route.params.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.row = response
        this.loading = false
        this.$stat.log({ page: 'comm', action: 'open comm', model: 'comm', model_id: response.id })
      } catch (e) {
        this.$logger.warn('/comm load error', e)
      }
    }
  },
  mounted () {
  },
  watch: {
  }
}
</script>

<style>
</style>
